.dice-container {
    width: 100px;
    height: 100px;
    position: relative;
    transform-style: preserve-3d;
    perspective: 400px; /* Adjust perspective for the 3D effect */
  }
  
  .dice {
    width: 100px;
    height: 100px;
    position: absolute;
    transform-style: preserve-3d;
    transform-origin: center center;
    animation: rollDice 10s infinite linear; /* Rotate the dice infinitely */
  }
  
  @keyframes rollDice {
    0% {
      transform: rotateX(0deg) rotateY(0deg);
    }
    100% {
      transform: rotateX(720deg) rotateY(720deg);
    }
  }
  
  .dice-face {
    position: absolute;
    width: 100px;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 2rem;
    border: 1px solid #a5a5a5;
    backface-visibility: hidden;
    background-color: black;
    // border: 3px solid ;
  // border-image: linear-gradient(to right, #FFFFFF, #FFFF00);
    svg {
      width: 100px;
      height: 100px;
    }
  }
  
  .face-1 {
    transform: rotateY(0deg) translateZ(50px);
  }
  
  .face-2 {
    transform: rotateY(90deg) translateZ(50px);
  }
  
  .face-3 {
    transform: rotateY(180deg) translateZ(50px);
  }
  
  .face-4 {
    transform: rotateY(-90deg) translateZ(50px);
  }
  
  .face-5 {
    transform: rotateX(-90deg) translateZ(50px);
  }
  
  .face-6 {
    transform: rotateX(90deg) translateZ(50px);
  }
  