.tabs {
  display: flex;
  // border-bottom: 1px solid #555;
  // background: black;
  flex-wrap: nowrap;
  justify-content: space-evenly;
  align-items: center;
  background-color: #222;
  }
  
  .tab {
    padding: 10px 15px;
    cursor: pointer;
    border-bottom: 2px solid transparent;
    transition: border-bottom-color 0.3s ease-in-out;
    color: #fff;
    font-size: 20px;
  
    &:hover {
      border-bottom-color: #fff;
    }
  
    &.active-tab {
      border-bottom-color: #fff;
      font-weight: bold;
      font-size: 20px;
    }
  }
  .tab-content {
    .content {
      display: none;
      padding: 20px;
      // border: 1px solid #555;
      margin-top: -1px;
      color: #fff;
      // background-color: #222;
      border-radius: 10px;
      &.active-content {
        display: flex;
        justify-content: center;
      }
    }
  }
  
  .skills-section {
    max-width: 900px;
    background-color: black;
    padding: 30px;
    border-radius: 10px;
    // box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
    box-shadow: 0 0 10px #fff;
    .ul-box {
      list-style-type: none;
      padding: 0;
      padding-top: 20px;
      margin: 0px;
    }
    .each-skill {
      display: flex;
      align-items: center;
      padding: 0px 20px 20px 20px;
    }
    .each-content {
      padding-left: 10px;
    }
  }
  
  .skills-section h2 {
    font-size: 28px;
    margin-bottom: 20px;
    color: #333;
  }
  
  .skills-container {
    display: flex;
    flex-direction: column;
  }
  
  
  .skill h3 {
    font-size: 18px;
    margin-bottom: 10px;
    color: #444;
  }
  
  .skill-bar {
    width: 100%;
    height: 20px;
    background-color: #ddd;
    border-radius: 5px;
    overflow: hidden;
  }
  
  .skill-level {
    height: 100%;
    background-color: #6cb2eb; /* Change color based on skill level */
    transition: width 1s ease-in-out; /* Add transition for smooth width change */
  }
  
  /* Hover effect */
  .skill:hover .skill-level {
    background-color: #4a90e2;
  }
  