/* Apply styling for the modal content */
.modal-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 60%;
    max-height: 60%;
    overflow-y: auto;
    background-color: black; /* Set background color */
    color: white; /* Set font color */
    padding: 20px;
    border-radius: 10px;
    opacity: 0;
    transition: opacity 0.9s ease-in-out;
  }
  
  /* Apply styling for the modal overlay */
  .modal-overlay {
    background-color: rgba(0, 0, 0, 0.7); /* Set overlay background color */
  }
.ReactModal__Overlay--after-open {
    background-color: #a5a5a570 !important;
}  
.modal-content-open {
    opacity: 1;
  }

  form {
    display: flex;
    flex-direction: column;
    max-width: 400px;
    margin: 0 auto;
  
    label {
      margin-bottom: 10px;
      display: flex;
      flex-direction: column;
      position: relative;
  
      &::after {
        content: '*'; /* Add asterisk for required fields */
        color: red;
        position: absolute;
        top: 8px;
        right: -12px;
      }
  
      select {
        width: 100%;
        padding: 8px;
        border: 1px solid #ccc;
        border-radius: 4px;
        margin-top: 5px;
      }
    }
  
    button {
      background-color: #007bff;
      color: white;
      border: none;
      padding: 10px 15px;
      border-radius: 4px;
      cursor: pointer;
      margin-top: 15px;
      transition: background-color 0.3s ease-in-out;
  
      &:hover {
        background-color: #0056b3;
      }
    }
  }
  